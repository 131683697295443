import { DataFrame } from '@grafana/data';

import { Cells } from '../../constants';
import { CellCustomOptionsType, FieldValidation } from '../../types';
import { configureDataFrame, convertDateToBE, getFieldConfig } from '../../utils';

import { TeamAdminToolFields } from './constants';
import { TeamAdminFiltersType, TeamAdminToolCreatePayload, TeamAdminToolCreateTableType } from './types';

interface ConfigTeamAdminToolData {
  dataFrame: DataFrame;
  handleDelete: (rowIndex: number) => void;
  hiddenFields?: string[];
  maxWorkload: number;
}

export function configTeamAdminToolData({
  dataFrame,
  hiddenFields,
  handleDelete,
  maxWorkload,
}: ConfigTeamAdminToolData): DataFrame {
  const options: CellCustomOptionsType = {
    align: 'left',
  };

  const emailOptions = {
    ...options,
    validation: [{ type: FieldValidation.EMAIL }],
  };

  const workloadRatioOptions = {
    ...options,
    validation: [{ type: FieldValidation.MAX, value: maxWorkload }],
  };

  const fieldConfigs = [
    { fields: [TeamAdminToolFields.Email], config: getFieldConfig(Cells.Input, { ...emailOptions }) },
    { fields: [TeamAdminToolFields.TeamMember], config: getFieldConfig(Cells.Input, { ...options }) },
    { fields: [TeamAdminToolFields.JiraID], config: getFieldConfig(Cells.Input, { ...options }) },
    { fields: [TeamAdminToolFields.OrgID], config: getFieldConfig(Cells.Input, { ...options, width: 115 }) },
    { fields: [TeamAdminToolFields.HourlyRate], config: getFieldConfig(Cells.Input, { ...options, width: 110 }) },
    { fields: [TeamAdminToolFields.YearlyHours], config: getFieldConfig(Cells.Input, { ...options, width: 110 }) },
    { fields: [TeamAdminToolFields.TeamID], config: getFieldConfig(Cells.Input, { ...options, width: 100 }) },
    {
      fields: [TeamAdminToolFields.WorkloadRatio],
      config: getFieldConfig(Cells.Input, { ...workloadRatioOptions, width: 120 }),
    },
    { fields: [TeamAdminToolFields.Role], config: getFieldConfig(Cells.Role, { ...options, width: 120 }) },
    { fields: [TeamAdminToolFields.StartDate], config: getFieldConfig(Cells.Date, { ...options, width: 120 }) },
    { fields: [TeamAdminToolFields.EndDate], config: getFieldConfig(Cells.Date, { ...options, width: 120 }) },
    {
      fields: [TeamAdminToolFields.ExcludeFromCapacity],
      config: getFieldConfig(Cells.Checkbox, { ...options, width: 80, align: 'center' }),
    },
  ];

  return configureDataFrame(dataFrame, hiddenFields, handleDelete, fieldConfigs);
}

export function getPayloadIDs(data: DataFrame): { [index: number]: { memberId?: number; teamId?: number } } {
  const memberIdField = data.fields.find((field) => field.name === TeamAdminToolFields.TeamMemberID);
  const teamIdField = data.fields.find((field) => field.name === TeamAdminToolFields.TeamID);

  const length = data.length;

  return Object.fromEntries(
    Array.from({ length }, (_, index) => [
      index,
      {
        memberId: memberIdField ? Number(memberIdField.values.get(index)) : undefined,
        teamId: teamIdField ? Number(teamIdField.values.get(index)) : undefined,
      },
    ])
  );
}

export function mapCreateUserPayload(data: TeamAdminToolCreateTableType, teamId: string): TeamAdminToolCreatePayload {
  return {
    email: data.email,
    internalOrgId: data.internalOrgId,
    name: data.name,
    hourlyRate: data.hourlyRate,
    yearlyHours: data.yearlyHours,
    teamIdsToDetails: {
      [teamId]: {
        workload: data.workloadRatio,
        roles: data.roles.map(({ role, rate }) => ({ roleId: Number(role.value), rate: Number(rate) })),
        excludedFromCapacity: data.excludedFromCapacity,
        workStartDate: convertDateToBE(data.workStartDate),
        workEndDate: convertDateToBE(data.workEndDate),
      },
    },
  };
}

export function filterTeamAdminTool(data: DataFrame, fieldName: string, filters: TeamAdminFiltersType): DataFrame {
  const teamMemberField = data.fields.find((field) => field.name === fieldName);
  const { teamMembers = [] } = filters || {};

  if (!teamMemberField || teamMembers.length === 0) {
    return data;
  }

  const filterMask = Array.from({ length: data.length }, (_, index) =>
    teamMembers.includes(teamMemberField.values.get(index))
  );

  return {
    ...data,
    length: filterMask.filter(Boolean).length,
    fields: data.fields.map((field) => ({
      ...field,
      values: Array.from({ length: data.length }, (_, index) => field.values.get(index)).filter(
        (_, index) => filterMask[index]
      ),
    })),
  };
}
