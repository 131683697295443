export const MARGIN = { top: 75, bottom: 60, right: 10, left: 10 };
// export const COLOR_ARRAY: string[] = [
//   '#0277B9',
//   '#CC3421',
//   '#013F7D',
//   '#FC9F5E',
//   '#92550D',
//   '#8C8F9B',
//   '#D8911E',
//   '#FDCB5E',
//   '#261090',
//   '#97ED71',
//   '#4E26D7',
//   '#49C231',
//   '#9066FB',
//   '#178315',
//   '#8F1847',
//   '#5EEB80',
//   '#D43758',
//   '#20BE66',
//   '#8A0E13',
//   '#0E8056',
//   '#FA787B',
//   '#3DC0E7',
// ];

export const COLOR_ARRAY = [
  '#0277B9',
  '#FF5722',
  '#4CAF50',
  '#FFC107',
  '#9C27B0',
  '#E91E63',
  '#3F51B5',
  '#00BCD4',
  '#8BC34A',
  '#FF9800',
  '#673AB7',
  '#FFEB3B',
  '#CDDC39',
  '#FF4081',
  '#00E5FF',
  '#795548',
  '#F44336',
  '#2196F3',
  '#FF6F00',
  '#009688',
  '#7B1FA2',
  '#C2185B',
  '#303F9F',
  '#0288D1',
  '#388E3C',
  '#FBC02D',
  '#D32F2F',
  '#1976D2',
  '#7CB342',
  '#F57C00',
  '#5D4037',
  '#512DA8',
  '#D50000',
  '#00ACC1',
  '#F4B400',
  '#7E57C2',
  '#BF360C',
  '#1E88E5',
  '#004D40',
  '#C6FF00',
  '#D81B60',
  '#0091EA',
  '#33691E',
  '#FFD600',
  '#AA00FF',
  '#FFAB00',
  '#6D4C41',
  '#827717',
  '#D500F9',
  '#880E4F',
];

// const colors = [
//   "#0277B9",
//   "#FF5722",
//   "#4CAF50",
//   "#FFC107",
//   "#9C27B0",
//   "#E91E63",
//   "#3F51B5",
//   "#00BCD4",
//   "#8BC34A",
//   "#FF9800",
//   "#673AB7",
//   "#FFEB3B",
//   "#CDDC39",
//   "#FF4081",
//   "#00E5FF",
//   "#795548",
//   "#F44336",
//   "#2196F3",
//   "#FF6F00",
//   "#009688"
// ];
//

export const DATA_SEPARATOR = ' $$ ';
