export const enum TeamHolidaysToolFields {
  HolidayDescription = 'Description',
  HolidayDate = 'Date',
  TypeOfHoliday = 'Type',
  Id = 'Id',
}

export const enum TeamHolidayTypes {
  NationalHoliday = 'BLABLO',
  TeamEvent = 'Team event',
}

export const hiddenFields = [TeamHolidaysToolFields.Id];
